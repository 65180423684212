






























































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import GroupComponent from '@common-src/mixins/group-component';
import { AssetEntityModel, AssetQueryModel } from '@common-src/entity-model/asset-entity';
import AssetService from '@common-src/service/asset';
import AssetDetailDrawer from './asset-detail-drawer.vue';
import AssetEditDialog from './asset-edit.vue';
import AssetSyncDeviceDialog from './asset-sync-device-dialog.vue';
import AssetBatchGroupComponent from './asset-batch-group-dialog.vue';
import AssetBatchLocationComponent from './asset-batch-location-dialog.vue';
import AssetLinkDeviceDialog from './asset-link-device-dialog.vue';
import AssetImportExcelComponent from './asset-import-dialog.vue';
import { TagEntityType, ViewModeType } from '@common-src/model/enum';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FaciltiyBatchLabelDialog from '@/pages/dashboard/facility-v2/facility-batch-add-label-dialog.vue';
import FacilityBatchDeleteLabelDialog from '@/pages/dashboard/facility-v2/facility-batch-delete-label-dialog.vue';
import LabelService from '@common-src/service/label';

@Component({
    components: {
        'asset-detail-drawer': AssetDetailDrawer,
        'asset-edit-dialog': AssetEditDialog,
        'asset-sync-device-dialog': AssetSyncDeviceDialog,
        'asset-batch-group-dialog': AssetBatchGroupComponent,
        'asset-batch-location-dialog': AssetBatchLocationComponent,
        'asset-link-device-dialog': AssetLinkDeviceDialog,
        'asset-import-excel-dialog': AssetImportExcelComponent,
        'facility-batch-label-dialog': FaciltiyBatchLabelDialog,
        'facility-batch-delete-label-dialog': FacilityBatchDeleteLabelDialog
    }
})
export default class AssetListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AssetEntityModel = AssetEntityModel;
    TagEntityType = TagEntityType;
    downloadTimer: any;
    // downloadQRcodeDataList: Array<any> = null;

    created() {
        this.initTable({
            service: AssetService,
            queryModel: new AssetQueryModel(),
            tableColumns: AssetEntityModel.getTableColumns()
        });
    }
    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as AssetQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/asset-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/asset-list');
        }
    }

    detailClick(model: AssetEntityModel) {
        if (this.$refs.detailDrawer) {
            (this.$refs.detailDrawer as AssetDetailDrawer).drawerShow(model);
        }
    }
    async downloadQRcodeClick(e) {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        this.clearTimer();
        this.startFullScreenLoading('正在下载二维码。。。');
        const selectRowIds: Array<string> = this.getSelectedRowIds();
        // 一次性提交多个图片的生成请求会触发net::ERR_INSUFFICIENT_RESOURCES问题
        // 每次触发5张二维码生成
        // const startOffset = 5;
        // let startIndex = 0;
        // this.startTimer = setInterval(() => {
        //     console.log('startIndex', startIndex);
        //     _.forEach(selectRowIds.slice(startIndex, startIndex + startOffset), item => {
        //         (this.$refs[`qrcode_${item}`] as any)?.batchDownloadClick();
        //     });
        //     if (startIndex >= selectRowIds.length) {
        //         clearInterval(this.startTimer);
        //         this.startTimer = null;
        //     }
        //     startIndex += startOffset;
        // }, 1000);
        const zip = new JSZip();
        let downloadIndex = 0;
        // 一个一个二维码排队处理
        this.downloadTimer = setInterval(() => {
            // console.log('downloadIndex', downloadIndex);
            if (downloadIndex < selectRowIds.length) {
                this.startFullScreenLoading(`正在下载第${downloadIndex + 1}/${selectRowIds.length}个二维码。。。`);
                const qrcodeComponent = this.$refs[`qrcode_${selectRowIds[downloadIndex]}`] as any;
                qrcodeComponent.batchDownloadClick();
                if (qrcodeComponent && qrcodeComponent.success && qrcodeComponent.qrCodeImgArr) {
                    const qrCodeImage = qrcodeComponent.qrCodeImgArr;
                    const fileName = qrcodeComponent.qrInfo.name;
                    if (qrCodeImage && qrCodeImage.url) {
                        console.log('fileName', fileName);
                        zip.file(fileName + '.png', qrCodeImage.url.substring(22), { base64: true });
                        downloadIndex++;
                        qrcodeComponent.previewVisible = false;
                    }
                }
            } else {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                zip.generateAsync({ type: 'blob' }).then(content => {
                    saveAs(content, '设备台账二维码.zip');
                    this.stopFullScreenLoading();
                });
            }
        }, 200);
    }

    syncDeviceClick(model: AssetEntityModel) {
        (this.$refs.assetSyncDeviceDialog as AssetSyncDeviceDialog).dialogOpen(this.GroupId, _.get(model, 'id'));
    }

    groupBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.batchGroupDialog as AssetBatchGroupComponent).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    locationBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.batchLocationDialog as AssetBatchLocationComponent).dialogOpen(this.getSelectedRowIds(), null, ViewModeType.NEW);
    }
    linkDeviceClick(model: AssetEntityModel) {
        (this.$refs.linkDeviceDialog as AssetLinkDeviceDialog).dialogOpen(model);
    }

    deleteBatchClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        const confirm = this.$confirm({
            title: '批量删除',
            content: `确认删除${this.selectedRowKeys?.length}个设备`,
            onOk: () => {
                this.startFullScreenLoading('正在删除');
                return AssetService.batchDelete(this.getSelectedRowIds()).then((ret) => {
                    this.showMessageSuccess('批量删除成功');
                    this.getList();
                    return true;
                }).catch((err) => {
                    throw err;
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }

    importExcelClick() {
        (this.$refs.importExcelDialog as AssetImportExcelComponent).dialogOpen();
    }
    exportExcelClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        this.startFullScreenLoading('正在导出');
        return AssetService.exportExcel(this.getSelectedRowIds()).then((ret) => {
            this.showMessageSuccess('导出成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    getExcelTemplate() {
        AssetService.getExcelTemplate().then((ret) => {
            this.showMessageSuccess('下载模板成功');
        });
    }

    clearTimer() {
        if (this.downloadTimer) {
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
        }
    }

    destroyed() {
        this.clearTimer();
    }

    addBatchLabelClick(tagEntityType:TagEntityType) {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        (this.$refs.formBatchLabelDialog as FaciltiyBatchLabelDialog).dialogOpen({ selectedRowKeys: this.getSelectedRowIds(), tagEntityType }, null, ViewModeType.NEW);
    }

    deleteBatchLabelClick() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择设备');
            return;
        }
        // 获取所有标签
        const params = {
            entityIds: this.getSelectedRowIds(),
            relType: TagEntityType.ASSET
        };

        LabelService.selectTagsByEntityIdsAndEntityType(params).then(res => {
            (this.$refs.formBatchDeleteLabelDialog as FacilityBatchDeleteLabelDialog).dialogOpen({ tagIds: res, selectedRowIds: this.getSelectedRowIds(), tagEntityType: TagEntityType.ASSET }, null, ViewModeType.NEW);
        });
    }
}

