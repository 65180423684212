var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "asset-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("设备台账")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "设备分组", bordered: false } },
                    [
                      _c("jtl-tree-group-component", {
                        attrs: {
                          "group-id": _vm.GroupId,
                          "group-type": _vm.GroupEntityType.ASSET,
                        },
                        on: { select: _vm.treeSelect },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "设备查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "设备列表" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  type: "primary",
                                  "table-header-child": "",
                                },
                                on: { click: _vm.syncDeviceClick },
                              },
                              [_vm._v("同步物设备")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: { "table-header-child": "" },
                                on: { click: _vm.getList },
                              },
                              [_vm._v("刷新")]
                            ),
                            _c(
                              "a-dropdown",
                              { attrs: { "table-header-child": "" } },
                              [
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    slot: "overlay",
                                  },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "1",
                                        on: { click: _vm.groupBatchClick },
                                      },
                                      [_vm._v("批量修改分组")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: { click: _vm.locationBatchClick },
                                      },
                                      [_vm._v("批量修改位置")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "3",
                                        on: { click: _vm.importExcelClick },
                                      },
                                      [_vm._v("批量导入")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "4",
                                        on: { click: _vm.exportExcelClick },
                                      },
                                      [_vm._v("批量导出")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "5",
                                        on: { click: _vm.deleteBatchClick },
                                      },
                                      [_vm._v("批量删除")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "6",
                                        on: { click: _vm.downloadQRcodeClick },
                                      },
                                      [_vm._v("批量下载二维码")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "7",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addBatchLabelClick(
                                              _vm.TagEntityType.ASSET
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("批量添加标签")]
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "8",
                                        on: {
                                          click: _vm.deleteBatchLabelClick,
                                        },
                                      },
                                      [_vm._v("批量删除标签")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  { staticStyle: { "margin-left": "8px" } },
                                  [
                                    _vm._v("批量操作"),
                                    _c("a-icon", { attrs: { type: "down" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: { "table-header-child": "" },
                                on: { click: _vm.getExcelTemplate },
                              },
                              [_vm._v("下载模板")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.addClick(
                                      new _vm.AssetEntityModel(_vm.GroupId)
                                    )
                                  },
                                },
                              },
                              [_vm._v("+ 添加")]
                            ),
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": _vm.TableRowKey,
                            size: _vm.TableSize,
                            "row-selection": {
                              selectedRowKeys: _vm.selectedRowKeys,
                              onChange: _vm.onSelectChange,
                              onSelect: _vm.onSelect,
                            },
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.detailClick(record)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(text))]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "qrcode",
                              fn: function (text, record) {
                                return [
                                  _c("jtl-qrcode-component", {
                                    ref: "qrcode_" + record.id,
                                    staticStyle: { width: "30px" },
                                    attrs: {
                                      "qr-size": 30,
                                      "qr-info": record.QRcodeInfo,
                                      "qr-text-size": 14,
                                      "qr-logo-size": 20,
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: record.HasDevice ? "primary" : "",
                                        shape: "round",
                                        size: "small",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkDeviceClick(record)
                                        },
                                      },
                                    },
                                    [_vm._v("物设备")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-edit-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editClick(record, true)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确认删除?",
                                        "ok-text": "确认",
                                        "cancel-text": "取消",
                                      },
                                      on: {
                                        confirm: function ($event) {
                                          return _vm.deleteClick(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a", { staticClass: "jtl-del-link" }, [
                                        _vm._v("删除"),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("asset-detail-drawer", { ref: "detailDrawer" }),
      _c("asset-edit-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("asset-sync-device-dialog", {
        ref: "assetSyncDeviceDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("asset-batch-group-dialog", {
        ref: "batchGroupDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("asset-batch-location-dialog", {
        ref: "batchLocationDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("asset-link-device-dialog", {
        ref: "linkDeviceDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("asset-import-excel-dialog", {
        ref: "importExcelDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("facility-batch-label-dialog", {
        ref: "formBatchLabelDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("facility-batch-delete-label-dialog", {
        ref: "formBatchDeleteLabelDialog",
        on: { dialogOK: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }