
import { AssetEntityModel, AssetDetailEntityModel } from '@common-src/entity-model/asset-entity';
import { ViewModeType } from '@common-src/model/enum';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del, download } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/asset`;
// const URL_PATH = `http://172.21.232.249:3000/mock/190/api/asset`;

class AssetService implements ICRUDQ<AssetEntityModel, AssetDetailEntityModel> {
    async create(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return res;
        // return new AssetEntityModel().toModel(res);
    }

    async update(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: AssetEntityModel):Promise<AssetEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: AssetDetailEntityModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new AssetEntityModel().toModel(item));
        return res;
    }

    async saveAsset(model: any, viewMode: any = null):Promise<any> {
        const url = viewMode === ViewModeType.NEW ? `${URL_PATH}/add` : `${URL_PATH}/update`;
        const res = await post(url, model);
        return res;
    }

    async batchLocation(ids: Array<string>, locationId: string):Promise<any> {
        const url = `${URL_PATH}/save/location/batch`;
        return await post(url, { ids, locationId });
    }

    async batchChangeGroup(ids: Array<string>, groupId: string) {
        const url = `${URL_PATH}/save/group/batch`;
        return await post(url, { ids, groupId });
    }

    async batchDelete(ids: Array<string>) {
        const url = `${URL_PATH}/delete/batch`;
        const params = {
            ids
        };
        const res = await post(url, params);
        return res;
    }

    async sync({ groupId, updatePattern }, deviceIds: Array<string>) {
        const url = `${URL_PATH}/sync`;
        const params = {
            groupId,
            updatePattern,
            deviceIds
        };
        const res = await post(url, params);
        return res;
    }

    async linkDevice(assetId: string, deviceIds: Array<string>) {
        const url = `${URL_PATH}/link`;
        const params = {
            id: assetId,
            deviceId: _.get(deviceIds, '[0]')
        };
        const res = await post(url, params);
        return res;
    }

    async importExcel(importData: any) {
        const url = `${URL_PATH}/import`;
        const params = importData;
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    async exportExcel(ids: Array<string>) {
        const url = `${URL_PATH}/export`;
        const res = await post(url, { ids }, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    async getExcelTemplate() {
        const url = `${URL_PATH}/template`;
        const res = await download(url);
        return res;
    }

    async getSummaryData(summaryType: string) {
        const url = `${URL_PATH}/summary/status/${summaryType}`;
        const res = await get(url);
        return res;
    }
}

export default new AssetService();
