






























import { Component, Ref } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { AssetEntityModel, AssetDetailEntityModel, AssetUsageEntityModel, AssetFactoryEntityModel, AssetMaintenanceEntityModel } from '@common-src/entity-model/asset-entity';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import AssetService from '@common-src/service/asset';
import { ICRUDQ } from '@common-src/model/interface';

@Component
export default class AssetEditDialog extends FormDialogComponent<AssetEntityModel> {
    @Ref()
    basicForm: FormComponent<AssetEntityModel>;
    @Ref()
    factoryForm: FormComponent<AssetFactoryEntityModel>;
    @Ref()
    usageForm: FormComponent<AssetUsageEntityModel>;
    @Ref()
    maintenanceForm: FormComponent<AssetMaintenanceEntityModel>;

    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    dialogOpen(model: AssetEntityModel, service: ICRUDQ<AssetEntityModel, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        if (needGetDetail && service) {
            this.startFullScreenLoading();
            service.retrieve(_.get(model, 'id')).then(res => {
                const basicModel = new AssetEntityModel().toModel(res);
                const factoryModel = new AssetFactoryEntityModel().toModel(res);
                const usageModel = new AssetUsageEntityModel().toModel(res);
                const maintenanceModel = new AssetMaintenanceEntityModel().toModel(res);
                this.assetDialogInit(basicModel, factoryModel, usageModel, maintenanceModel, viewMode);
            }).catch(err => $log.error(err)).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            this.assetDialogInit(model, new AssetFactoryEntityModel(), new AssetUsageEntityModel(), new AssetMaintenanceEntityModel(), viewMode);
        }
    }

    drawDialogOpen(assetModel: any, viewMode: ViewModeType = ViewModeType.UPDATE) {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        const basicModel = new AssetEntityModel().toModel(assetModel);
        const factoryModel = new AssetFactoryEntityModel().toModel(assetModel);
        const usageModel = new AssetUsageEntityModel().toModel(assetModel);
        const maintenanceModel = new AssetMaintenanceEntityModel().toModel(assetModel);
        this.assetDialogInit(basicModel, factoryModel, usageModel, maintenanceModel, viewMode);
    }

    assetDialogInit(basicModel: AssetEntityModel, factoryModel: AssetFactoryEntityModel, usageModel: AssetUsageEntityModel, maintenanceModel: AssetMaintenanceEntityModel, viewMode: ViewModeType) {
        this.$nextTick(() => {
            this.basicForm?.initForm(basicModel, viewMode);
            this.factoryForm?.initForm(factoryModel, viewMode);
            this.usageForm?.initForm(usageModel, viewMode);
            this.maintenanceForm?.initForm(maintenanceModel, viewMode);
        });
    }

    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    dialogOK(): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.all([this.basicForm.submitForm(), this.usageForm.submitForm()]).then(res => {
                const model = Object.assign(this.basicForm.formModel.toService(), this.factoryForm.formModel.toService(), this.usageForm.formModel.toService(), this.maintenanceForm.formModel.toService());
                // const model = {
                //     'assetCode': 'zk002',
                //     'assetName': '主机001',
                //     'assetTypeName': '主机',
                //     'assetStatus': 'ade4d580-1952-11eb-b16f-b57934b09a18',
                //     'department': 'IT',
                //     'chargePerson': '里斯',
                //     'chargePersonPhone': '18222222222',
                //     'manufacturingCode': 'zk001',
                //     'model': '电脑主机',
                //     'factory': '金螳螂',
                //     'contactPerson': '张三',
                //     'contactPhone': '18111111111',
                //     'mainTechnicalParameter': 'i7 11700 256G',
                //     'groupId': 'a616af48-c118-4840-b80e-97bd7c8a8a6a',
                //     'locationId': '19996580-90a8-43b3-8bb4-624c95fe4963',
                //     'remark': '测试数据',
                //     'maintenanceCycle': '2',
                //     'firstDateTs': 1647505780820
                // };
                AssetService.saveAsset(model, this.viewMode).then(res => {
                    resolve(null);
                    this.dialogClose();
                    this.$emit('dialogOK', res);
                    this.showMessageSuccess('保存成功');
                }).catch(err => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
     * 关闭弹窗
     */
    dialogClose(): void {
        if (this.basicForm) {
            this.basicForm.resetFields();
            this.basicForm.clearValidate();
        }
        if (this.factoryForm) {
            this.factoryForm.resetFields();
            this.factoryForm.clearValidate();
        }
        if (this.usageForm) {
            this.usageForm.resetFields();
            this.usageForm.clearValidate();
        }
        if (this.maintenanceForm) {
            this.maintenanceForm.resetFields();
            this.maintenanceForm.clearValidate();
        }
        this.dialogVisible = false;
        this.dialogTitle = null;
    }
}
