var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c("div", { staticClass: "asset-detail-component table-component" }, [
        _c(
          "div",
          { staticClass: "page-body page-body-bg-white" },
          [
            _c(
              "a-descriptions",
              {
                staticClass: "tab-margin-16",
                attrs: { bordered: "", column: 2 },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "title" }, slot: "title" },
                  [
                    _c("span", [_vm._v("基本信息")]),
                    _c(
                      "jtl-button",
                      {
                        staticClass: "description-title-button",
                        attrs: { type: "primary" },
                        on: { click: _vm.editClick },
                      },
                      [_vm._v("编辑")]
                    ),
                  ],
                  1
                ),
                _c("a-descriptions-item", { attrs: { label: "设备编号" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "assetCode"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "设备名称" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "assetName"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "设备类型" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "assetTypeName")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "设备状态" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "assetStatus"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "是否采集" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._.get(_vm.assetModel, "deviceName") ? "是" : "否"
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "a-descriptions-item",
                  { attrs: { label: "设备二维码" } },
                  [
                    _vm.assetQRcodeInfo
                      ? _c("jtl-qrcode-component", {
                          staticStyle: { width: "30px" },
                          attrs: {
                            "qr-size": 30,
                            "qr-info": _vm.assetQRcodeInfo,
                            "qr-text-size": 14,
                            "qr-logo-size": 20,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-descriptions",
              {
                staticClass: "tab-margin-16",
                attrs: { bordered: "", column: 2, title: "出厂铭牌" },
              },
              [
                _c("a-descriptions-item", { attrs: { label: "出厂编号" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "manufacturingCode")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "规格/型号" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "model"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "生产厂家" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "factory"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "厂家联系人" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "contactPerson")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "联系人电话" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "contactPhone")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "a-descriptions-item",
                  { attrs: { label: "主要技术参数" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("text")(
                            _vm._.get(_vm.assetModel, "mainTechnicalParameter")
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "a-descriptions",
              {
                staticClass: "tab-margin-16",
                attrs: { bordered: "", column: 2, title: "使用信息" },
              },
              [
                _c("a-descriptions-item", { attrs: { label: "所属分组" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "groupName"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "安装位置" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "locationName")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "使用部门" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "department"))
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "设备负责人" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "chargePerson")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "负责人电话" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "chargePersonPhone")
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "备注" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(_vm._.get(_vm.assetModel, "remark"))
                      ) +
                      " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "a-descriptions",
              {
                staticClass: "tab-margin-16",
                attrs: { bordered: "", column: 2, title: "保养/维修信息" },
              },
              [
                _c("a-descriptions-item", { attrs: { label: "投运日期" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._f("dateFormat")(
                            _vm._.get(_vm.assetModel, "firstDateTs"),
                            "YYYY-MM-DD"
                          )
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "保养周期" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "maintenanceCycle")
                        )
                      ) +
                      " 个月 "
                  ),
                ]),
                _c(
                  "a-descriptions-item",
                  { attrs: { label: "首次保养日期" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("text")(
                            _vm._f("dateFormat")(
                              _vm._.get(
                                _vm.assetModel,
                                "firstMaintenanceDateTs"
                              ),
                              "YYYY-MM-DD"
                            )
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("a-descriptions-item", { attrs: { label: "巡检周期" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "inspectionCycle")
                        )
                      ) +
                      " 小时 "
                  ),
                ]),
                _c("a-descriptions-item", { attrs: { label: "距下次保养" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("text")(
                          _vm._.get(_vm.assetModel, "nextMaintenanceDay")
                        )
                      ) +
                      " 天 "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("asset-edit-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }