import { BaseModel, BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlModel, FormControlOptionModel, FormControlDateModel } from '@common-src/model/form-control';
import { ThingsTemplateQueryModel } from './things-template-entity';
import CommonService from '@common-src/service/common';
import OrgService from '@common-src/service/org';
import { GroupEntityType } from '@common-src/model/enum';
import SpaceV2Service from '@common-src/service/space-v2';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { MOBILE_REGEXP, NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import AssetGroupService from '@common-src/service/asset-group';
import moment, { Moment } from 'moment';
import LabelService from '@common-src/service/label';
import { UserStoreModule } from '@common-src/store/modules/user';

export class AssetEntityModel extends BaseEntityModel {
    // constructor(groupId?: string) {
    //     super();
    //     this.groupId = groupId;
    //     this.groupType = GroupEntityType.ASSET;
    // }

    /**
     * 设备编号
     */
     @FormControl({
         label: '设备编号',
         type: FormControlType.TEXT,
         required: true,
         layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
     } as FormControlTextModel)
    assetCode: string = undefined;

    @FormControl({
        label: '设备名称',
        type: FormControlType.TEXT,
        required: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    assetName: string = undefined;

    @FormControl({
        label: '设备类型',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    assetTypeName: string = undefined;

    @FormControl({
        label: '设备状态',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAssetStatusPropertyOptions,
        required: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    assetStatus: string = undefined;
    assetStatusName: string = undefined;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    tagIds: string[] = undefined;

    groupName: string = undefined;
    department: string = undefined;
    chargePerson: string = undefined;
    chargePersonPhone: string = undefined;
    deviceCode: string = undefined;
    deviceName: string = undefined;
    manufacturingCode: string = undefined;

    get HasDevice() {
        return !!(this.deviceCode || this.deviceName);
    }

    get QRcodeInfo() {
        const qrcodeDomain = UserStoreModule?.CurrentProject?.domain || window.location.origin;
        // const qrcodeDomain = 'https://hxh.sznimbus.com';
        return {
            id: `${qrcodeDomain}/asset/${this.id}`,
            name: this.assetName,
            code: this.assetCode,
            factoryCode: this.manufacturingCode
        };
    }

    static getTableColumns() {
        return [
            {
                title: '设备编号',
                dataIndex: 'assetCode'
            },
            {
                title: '设备名称',
                dataIndex: 'assetName',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '所属分组',
                dataIndex: 'groupName'
            },
            {
                title: '使用部门',
                dataIndex: 'department'
            },
            {
                title: '负责人',
                dataIndex: 'chargePerson'
            },
            {
                title: '联系电话',
                dataIndex: 'chargePersonPhone'
            },
            {
                title: '设备状态',
                dataIndex: 'assetStatus'
            },
            {
                title: '设备二维码',
                dataIndex: 'qrcode',
                scopedSlots: { customRender: 'qrcode' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 200,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (!json.tagIds) {
            this.tagIds = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class AssetFactoryEntityModel extends BaseModel {
    @FormControl({
        label: '出厂编号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturingCode: string = undefined;

    @FormControl({
        label: '规格/型号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    model: string = undefined;

    @FormControl({
        label: '生产厂家',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    factory: string = undefined;

    @FormControl({
        label: '厂家联系人',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    contactPerson: string = undefined;

    @FormControl({
        label: '联系人电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        pattern: MOBILE_REGEXP
    } as FormControlTextModel)
    contactPhone: string = undefined;

    @FormControl({
        label: '主要技术参数',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    mainTechnicalParameter: string = undefined;
}

export class AssetUsageEntityModel extends BaseModel {
    @FormControl({
        label: '所属分组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: AssetGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.ASSET,
        required: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    groupId: string = undefined;

    @FormControl({
        label: '安装位置',
        type: FormControlType.TREE_SELECT,
        optionsPromise: SpaceV2Service.getLocationTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlModel<string>)
    locationId: string = undefined;

    @FormControl({
        label: '使用部门',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    department: string = undefined;

    @FormControl({
        label: '设备负责人',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    chargePerson: string = undefined;

    @FormControl({
        label: '负责人电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        pattern: MOBILE_REGEXP
    } as FormControlTextModel)
    chargePersonPhone: string = undefined;

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA,
        layout: { labelCol: 3, wrapperCol: 18, width: '100%' }
    } as FormControlTextModel)
    remark: string = undefined;
}

export class AssetMaintenanceEntityModel extends BaseModel {
    @FormControl({
        label: '投运日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        showTime: false
    } as FormControlTextModel)
    firstDateTs: Moment = undefined;

    @FormControl({
        label: '保养周期',
        type: FormControlType.TEXT,
        suffix: '个月',
        pattern: NUMBER_INT_REGEXP,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    maintenanceCycle: string = undefined;

    @FormControl({
        label: '首次保养日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        showTime: false
    } as FormControlTextModel)
    firstMaintenanceDateTs: Moment = undefined;

    @FormControl({
        label: '巡检周期',
        type: FormControlType.TEXT,
        suffix: '小时',
        pattern: NUMBER_INT_REGEXP,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    inspectionCycle: string = undefined;

    // @FormControl({
    //     label: '距下次保养',
    //     type: FormControlType.TEXT,
    //     suffix: '天',
    //     pattern: NUMBER_INT_REGEXP,
    //     layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    // } as FormControlTextModel)
    nextMaintenanceDay: string = undefined;

    toModel(json): any {
        super.toModel(json);
        if (json.firstDateTs) {
            this.firstDateTs = moment(json.firstDateTs);
        }
        if (json.firstMaintenanceDateTs) {
            this.firstMaintenanceDateTs = moment(json.firstMaintenanceDateTs);
        }
        return this;
    }

    toService(): any {
        const data = super.toService();
        data.firstDateTs = this.firstDateTs?.toDate().getTime();
        data.firstMaintenanceDateTs = this.firstMaintenanceDateTs?.toDate().getTime();
        return data;
    }
}

export class AssetDetailEntityModel extends BaseModel {
    @FormControl({
        label: '规格/型号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    specification: string = undefined;

    @FormControl({
        label: '参数',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    parameter: string = undefined;

    @FormControl({
        label: '品牌',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    brand: string = undefined;

    @FormControl({
        label: '厂家名称',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturerName: string = undefined;

    @FormControl({
        label: '厂家电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturerTel: string = undefined;

    @FormControl({
        label: '生产厂家',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    manufacturer: string = undefined;

    @FormControl({
        label: '供应商',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    supplier: string = undefined;

    @FormControl({
        label: '供应商电话',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    supplierTel: string = undefined;

    @FormControl({
        label: '生产日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    productionTime: string = undefined;

    @FormControl({
        label: '出厂设备编号',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    deviceNo: string = undefined;

    @FormControl({
        label: '到货日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    arrivalTime: string = undefined;

    @FormControl({
        label: '安装单位',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    installUnit: string = undefined;

    @FormControl({
        label: '验收单位',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    acceptUnit: string = undefined;

    @FormControl({
        label: '验收时间',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    acceptTime: string = undefined;

    @FormControl({
        label: '维护周期',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    maintenanceCycle: string = undefined;

    @FormControl({
        label: '原厂到保日期',
        type: FormControlType.SELECT_DATE,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlDateModel)
    keepTime: string = undefined;

    @FormControl({
        label: '使用部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: OrgService.getSelectOrgTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlOptionModel)
    useDepartment: string = undefined;
    useDepartmentName: string = undefined;

    @FormControl({
        label: '管理部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: OrgService.getSelectOrgTree,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' },
        required: false
    } as FormControlOptionModel)
    manageDepartment: string = undefined;
    manageDepartmentName: string = undefined;

    @FormControl({
        label: '外形尺寸',
        type: FormControlType.TEXT,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    size: string = undefined;

    @FormControl({
        label: '重量',
        type: FormControlType.NUMBER,
        step: 0.1,
        message: 'Kg',
        noMessageTooltip: true,
        layout: { labelCol: 6, wrapperCol: 15, width: '50%' }
    } as FormControlTextModel)
    weight: string = undefined;
}

export class AssetQueryModel extends ThingsTemplateQueryModel {
    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getAssetStatusPropertyOptions,
        hasAllOption: true
    })
    status: string = '';

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 10
    })
    assetName: string = undefined;

    toService() {
        return {
            params: {
                groupIds: this.GroupIds,
                assetName: this.assetName,
                status: this.status || undefined
            }
        };
    }
}
