var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "jtl-entity-dialog-component" },
        [
          _c(
            "a-collapse",
            { attrs: { "active-key": ["1", "2", "3", "4"] } },
            [
              _c(
                "a-collapse-panel",
                { key: "1", attrs: { header: "基本信息" } },
                [_c("jtl-form", { ref: "basicForm" })],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "2", attrs: { header: "出厂铭牌" } },
                [_c("jtl-form", { ref: "factoryForm" })],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "3", attrs: { header: "使用信息" } },
                [_c("jtl-form", { ref: "usageForm" })],
                1
              ),
              _c(
                "a-collapse-panel",
                { key: "4", attrs: { header: "保养信息" } },
                [_c("jtl-form", { ref: "maintenanceForm" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("jtl-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取消"),
          ]),
          _vm.viewMode !== _vm.ViewModeType.VIEW
            ? _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }