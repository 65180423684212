








































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetGroupService from '@common-src/service/asset-group';
import AssetService from '@common-src/service/asset';
import { FormControlType } from '@common-src/model/form-control';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import FormComponent from '@common-src/mixins/form-component';
import { AssetEntityModel } from '@common-src/entity-model/asset-entity';

@Component
export default class AssetLinkDeviceDialog extends Mixins(TransformComponent, TableComponent) {
    model: AssetEntityModel = null;
    @Ref('assetSyncForm')
    jtlForm: FormComponent<any>;
    GroupEntityType = GroupEntityType;

    created() {
        this.pageSize = null;
        this.isLoadMore = true;
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.initTable({ listFunc: DeviceService.query, queryModel: deviceQueryModel });
    }

    dialogOpen(model: AssetEntityModel) {
        this.model = model;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        (this.queryModel as DeviceQueryModel).groupIds = null;
        (this.queryModel as DeviceQueryModel).name = null;
        this.getList();
    }

    dialogOK() {
        if (!this.rightDataList || this.rightDataList.length === 0) {
            this.showMessageWarning('请选择关联的设备');
            return;
        }
        if (this.rightDataList.length > 1) {
            this.showMessageWarning('只能关联一个设备');
            return;
        }
        return AssetService.linkDevice(this.model.id, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }
}
