










































































































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { AssetEntityModel, AssetDetailEntityModel } from '@common-src/entity-model/asset-entity';
import AssetEditDialog from './asset-edit.vue';
import AssetService from '@common-src/service/asset';
import DeviceStatusComponent from '@common-src/pages3/dashboard/device/device-status.vue';

@Component({
    components: {
        'asset-edit-dialog': AssetEditDialog,
        'device-status-component': DeviceStatusComponent
    }
})
export default class AssetDetailDrawer extends DrawerComponent {
    assetModel: any = null;
    assetId: string = null;
    assetQRcodeInfo: any = null;

    drawerShow(model: AssetEntityModel) {
        this.assetQRcodeInfo = model.QRcodeInfo;
        this.drawerOpen(model.id, `设备详情-${model.assetName}`);
        this.init(model.id);
    }

    init(assetId: string) {
        this.assetId = assetId;
        this.startFullScreenLoading();
        AssetService.retrieve(assetId)
            .then(res => {
                this.assetModel = res;
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        this.init(this.assetId);
    }
    editClick() {
        (this.$refs.formDialog as AssetEditDialog).drawDialogOpen(this.assetModel);
    }
}
