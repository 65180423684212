

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import AssetService from '@common-src/service/asset';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class AssetImportExcelComponent extends FormDialogComponent<any> {
    isProtocol: boolean;
    dialogOpen(): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量导入设备';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'excelFile',
                        label: '选择文件',
                        type: FormControlType.UPLOAD,
                        accept: '.xlsx',
                        notUploadOss: true,
                        maxFileNumber: 1,
                        required: true
                    },
                    {
                        key: 'updatePattern',
                        label: '设备已存在时',
                        type: FormControlType.RADIO_GROUP,
                        options: [{ name: '更新数据', value: 'UPDATE' }, { name: '忽略（本次）数据', value: 'IGNORE' }],
                        required: true,
                        value: 'UPDATE'
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const formData = new FormData();
                formData.append('multipartFile', _.get(this.jtlForm.formModel.excelFile, '[0].file'));
                formData.append('updatePattern', this.jtlForm.formModel.updatePattern);
                AssetService.importExcel(formData).then(res => {
                    if (!res || res.er !== -1) {
                        // 导入失败
                        // 文件流 或者 json的er不等于-1
                        this.showMessageError(res?.erMessage || '批量导入设备失败');
                        resolve(null);
                    } else {
                        // 导入成功，返回json
                        this.showMessageSuccess(res?.items?.message || '批量导入设备成功');
                        this.dialogClose();
                        this.$emit('dialogOK');
                        resolve(null);
                    }
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
