




































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetGroupService from '@common-src/service/asset-group';
import AssetService from '@common-src/service/asset';
import { FormControlType } from '@common-src/model/form-control';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import FormComponent from '@common-src/mixins/form-component';

@Component
export default class AssetSyncDeviceDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;
    @Ref('assetSyncForm')
    jtlForm: FormComponent<any>;

    created() {
        this.pageSize = null;
        this.isLoadMore = true;
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.initTable({ listFunc: DeviceService.query, queryModel: deviceQueryModel });
    }

    dialogOpen(groupId: string, modelId: string) {
        this.modelId = modelId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        (this.queryModel as DeviceQueryModel).groupIds = null;
        (this.queryModel as DeviceQueryModel).name = null;
        this.getList();
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'groupId',
                        label: '设备分组',
                        value: groupId,
                        type: FormControlType.TREE_SELECT,
                        optionsPromise: AssetGroupService.getGroupTree,
                        required: true,
                        message: '按设备分组批量添加设备，如物分组请先创建设备分组',
                        noMessageTooltip: false
                    },
                    {
                        key: 'updatePattern',
                        label: '设备已存在时',
                        type: FormControlType.RADIO_GROUP,
                        options: [{ name: '更新数据', value: 'UPDATE' }, { name: '忽略（本次）数据', value: 'IGNORE' }],
                        required: true,
                        value: 'UPDATE'
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK() {
        if (!this.rightDataList || this.rightDataList.length === 0) {
            this.showMessageWarning('请选择同步的设备');
            return;
        }
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then(res => {
                AssetService.sync(this.jtlForm.formModel, _.map(this.rightDataList, item => item.id)).then((res) => {
                    this.showMessageSuccess(res?.message);
                    resolve(null);
                    this.$emit('dialogOK');
                    this.dialogClose();
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }
}
